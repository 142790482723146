import { z } from 'zod';

import { CognitoUserAttributes } from './cognito';

export enum UserGroup {
  ADMIN = 'admin',
}

export const USER_GROUPS = [UserGroup.ADMIN] as const;
export const buildUserGroupSchema = () => z.enum(USER_GROUPS);

export const buildAuthenticatedUserSchema = () =>
  z.object({
    userId: z.string(),
    email: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    nickname: z.string().optional(),
    userGroups: z.array(buildUserGroupSchema()).optional(),
  });
export type AuthenticatedUser = z.infer<ReturnType<typeof buildAuthenticatedUserSchema>>;

export function getUserFullName(userAttributes: {
  email: string;
  firstName?: string;
  lastName?: string;
  nickname?: string;
}) {
  if (userAttributes.lastName && userAttributes.firstName) {
    return `${userAttributes.firstName} ${userAttributes.lastName}`;
  }
  return (
    userAttributes.lastName ||
    userAttributes.firstName ||
    userAttributes.nickname ||
    userAttributes.email
  );
}
