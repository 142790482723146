import { useWindowDimensions } from 'react-native';
import type { StyleProp } from 'react-native';

const BREAKPOINTS = {
  mobile: 0,
  tablet: 768,
  desktop: 1280,
};

export type ScreenType = keyof typeof BREAKPOINTS;

export function useScreenType() {
  const { width } = useWindowDimensions();
  return getScreenTypeByWindowWidth(width);
}

export function useResponsiveValue<T>(values: Record<ScreenType, T>) {
  const screenType = useScreenType();
  return values[screenType];
}

export function useResponsiveStyle(styles: Record<ScreenType, StyleProp<any>>) {
  const screenType = useScreenType();
  return styles[screenType];
}

function getScreenTypeByWindowWidth(width: number) {
  if (width < BREAKPOINTS.tablet) return 'mobile';
  if (width < BREAKPOINTS.desktop) return 'tablet';
  return 'desktop';
}
