import DummyDarkLogo from '@innovigo/consumer-app-sdk/components/Logo/DummyDarkLogo';
import DummyLightLogo from '@innovigo/consumer-app-sdk/components/Logo/DummyLightLogo';
import { impossible } from '@innovigo/types';
import { useColorScheme } from '@innovigo/ui/components/ColorScheme';

export function Logo() {
  const colorScheme = useColorScheme();

  return colorScheme === 'dark' ? (
    <DummyDarkLogo />
  ) : colorScheme === 'light' ? (
    <DummyLightLogo />
  ) : (
    impossible(colorScheme)
  );
}
