import { Button } from '@innovigo/ui/components/Button';
import { Text } from '@innovigo/ui/components/Text';
import * as Sentry from '@sentry/react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { StyleSheet, View } from 'react-native';

export function ErrorBoundary({ children }: { children?: React.ReactNode }) {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <Sentry.ErrorBoundary
      onReset={reset}
      fallback={({ resetError }) => (
        <View style={styles.container}>
          <Text>Oops! Something went wrong</Text>
          <Button title="Try again" onPress={() => resetError()} style={styles.button} />
        </View>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginTop: 16,
  },
});
