import { Badge } from '@innovigo/ui/components/Badge';
import { Icon, IconProps } from '@innovigo/ui/components/Icon';
import { Text } from '@innovigo/ui/components/Text';
import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { useResponsiveStyle, useScreenType } from '@innovigo/ui/hooks/useResponseStyle';
import { Pressable, StyleSheet, View } from 'react-native';

export function NavLinkBase({
  icon,
  isActive,
  label,
  onPress,
  unreadBadge,
}: {
  icon: IconProps['name'];
  isActive: boolean;
  label: string;
  onPress: () => void;
  unreadBadge?: number;
}) {
  const theme = useTheme();
  const textColor = isActive ? theme.neutral['05'] : theme.neutral['04'];
  const screenType = useScreenType();

  return (
    <Pressable
      onPress={onPress}
      style={[
        styles.container,
        useResponsiveStyle({
          mobile: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          },
          tablet: {
            flexShrink: 0,
            height: 48,
            borderRadius: 12,
            alignItems: 'center',
            justifyContent: 'center',
          },
          desktop: {
            flexShrink: 0,
            height: 48,
            backgroundColor: isActive ? theme.neutral['02'] : undefined,
            borderRadius: 12,
            padding: 12,
          },
        }),
      ]}
    >
      <View
        style={[
          styles.leftContainer,
          useResponsiveStyle({
            mobile: {
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
            tablet: {
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
            desktop: {
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            },
          }),
        ]}
      >
        <View
          style={useResponsiveStyle({
            mobile: {
              paddingHorizontal: 8,
              paddingTop: 8,
            },
            tablet: {
              paddingHorizontal: 8,
              paddingTop: 8,
            },
            desktop: {},
          })}
        >
          <Icon name={icon} color={textColor} size={24} />
          {unreadBadge && (screenType === 'mobile' || screenType === 'tablet') ? (
            <Badge
              content={Math.min(99, unreadBadge)}
              size={16}
              containerStyle={styles.badgeMobile}
            />
          ) : null}
        </View>
        <Text
          allowFontScaling={false}
          numberOfLines={1}
          style={[
            {
              color: textColor,
            },
            useResponsiveStyle({
              mobile: {
                fontSize: 9,
                marginTop: 6,
                paddingBottom: 8,
              },
              tablet: {
                fontSize: 9,
                lineHeight: 12,
                marginTop: 1,
              },
              desktop: {
                fontSize: 15,
                marginLeft: 12,
              },
            }),
          ]}
        >
          {label}
        </Text>
      </View>
      {unreadBadge && screenType === 'desktop' ? (
        <View style={styles.rightContainer}>
          <Badge
            content={Math.min(999, unreadBadge)}
            size={20}
            containerStyle={
              unreadBadge > 99
                ? {
                    width: 28,
                  }
                : {}
            }
          />
        </View>
      ) : null}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftContainer: {},
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  badgeMobile: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
