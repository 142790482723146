import { impossible } from '@innovigo/types';
import { Button } from '@innovigo/ui/components/Button';
import { CodeVerificationField } from '@innovigo/ui/components/CodeVerificationField';
import { Text } from '@innovigo/ui/components/Text';
import { useTheme } from '@innovigo/ui/components/Theme/ThemeProvider';
import { EmailField } from '@innovigo/ui/components/form/EmailField';
import { TextField } from '@innovigo/ui/components/form/TextField';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Pressable, StyleSheet, View } from 'react-native';
import type { ViewProps } from 'react-native';

import { useStorageState } from '../hooks/useStorageState';
import { useAuth } from './AuthProvider';

enum ScreenContent {
  Signup = 'SIGNUP',
  Login = 'LOGIN',
  ConfirmCode = 'CONFIRM_CODE',
  ForgotPassword = 'FORGOT_PASSWORD',
  ConfirmForgotPassword = 'CONFIRM_FORGOT_PASSWORD',
  CompleteProfile = 'COMPLETE_PROFILE',
}

export type AuthFormProps = {
  containerStyle?: ViewProps['style'];
  onLogin?: () => void;
  onSignUp?: () => void;
  schema?: {
    firstName?: boolean;
    lastName?: boolean;
    nickname?: boolean;
  };
  storageKeys: {
    email: string;
  };
};

const defaultValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  nickname: '',
  confirmCode: [] as string[],
};

export function AuthForm({
  containerStyle,
  onLogin,
  onSignUp,
  schema = {
    firstName: true,
    lastName: true,
  },
  storageKeys,
}: AuthFormProps) {
  const theme = useTheme();
  const [showContent, setShowContent] = useState(ScreenContent.Login);
  const [confirmCodemsg, setconfirmCodemsg] = useState('');

  const {
    login,
    signUp,
    confirmSignUp,
    resendConfirmationCode,
    forgotPassword,
    confirmForgotPassword,
    completeNewPasswordChallenge,
  } = useAuth();
  const loginMutation = useMutation(login);
  const signUpMutation = useMutation(signUp);
  const confirmSignUpMutation = useMutation(confirmSignUp);
  const resendConfirmationCodeMutation = useMutation(resendConfirmationCode);
  const forgotPasswordMutation = useMutation(forgotPassword);
  const confirmForgotPasswordMutation = useMutation(confirmForgotPassword);
  const completeNewPasswordChallengeMutation = useMutation(completeNewPasswordChallenge);

  const { load: loadStoredEmail, save: saveEmail } = useStorageState<string>(storageKeys.email);
  const form = useForm({
    defaultValues,
  });
  // Get stored email into form
  useEffect(() => {
    loadStoredEmail().then((storedEmail) => {
      if (!storedEmail) return;
      if (form.formState.dirtyFields.email) {
        // email field already touched
        return;
      }
      form.setValue('email', storedEmail);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadStoredEmail]);

  //Handle function
  const handleSwitchConfirmCode = (hintmsg?: string) => {
    if (hintmsg) setconfirmCodemsg(hintmsg);
    setShowContent(ScreenContent.ConfirmCode);
  };
  const handleSwitch = () => {
    if (showContent === ScreenContent.Signup) {
      setShowContent(ScreenContent.Login);
    } else if (showContent === ScreenContent.Login) {
      setShowContent(ScreenContent.Signup);
    }
  };

  /**
   * This function will use to handle the event when click the Login button
   */
  const handleLogin = async ({ email, password }: { email: string; password: string }) => {
    try {
      const result = await loginMutation.mutateAsync({
        email,
        password,
      });
      if (result.kind === 'NEW_PASSWORD_REQUIRED') {
        // clear temporary password
        form.setValue('password', '');
        setShowContent(ScreenContent.CompleteProfile);
      } else if (result.kind === 'SUCCESS') {
        onLogin?.();
      } else {
        impossible(result);
      }
    } catch (err: any) {
      //If user signed up but didn't confirm the code in first 24hours, the next time login will raise
      //Error saying User is not confirmed
      if (err.code === 'UserNotConfirmedException') {
        await resendConfirmationCodeMutation.mutateAsync({ email });
        handleSwitchConfirmCode(err.message);
        return;
      } else if (err.code === 'NotAuthorizedException') {
        form.setError('password', {
          type: 'server',
          message: err.message,
        });
        return;
      }
      throw err;
    }
  };
  /**
   * This function will use to handle the event when click the Signup button
   */
  const handleSignup = async (values: {
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
    nickname?: string;
  }) => {
    try {
      await signUpMutation.mutateAsync(values);
    } catch (err: any) {
      if (err.code === 'UsernameExistsException') {
        form.setError('email', {
          type: 'server',
          message: 'Email already taken',
        });
        return;
      }
      throw err;
    }
    setShowContent(ScreenContent.ConfirmCode);
    onSignUp?.();
  };

  /**
   * This function will use to handle the event when click the Confirm code button
   */
  const handleCodeConfirm = async ({
    email,
    password,
    confirmCode,
  }: {
    email: string;
    password: string;
    confirmCode: string;
  }) => {
    if (confirmCode.length !== 6) {
      form.setError('confirmCode', {
        type: 'server',
        message: 'Invalid confirmation code',
      });
      return;
    }
    try {
      await confirmSignUpMutation.mutateAsync({
        email: email,
        code: confirmCode,
      });
    } catch (err: any) {
      if (err.code === 'CodeMismatchException') {
        form.setError('confirmCode', {
          type: 'server',
          message: 'Invalid confirmation code',
        });
        return;
      } else if (err.code === 'ExpiredCodeException') {
        form.setError('confirmCode', {
          type: 'server',
          message: 'Expired confirmation code',
        });
        return;
      }
      throw err;
    }
    await handleLogin({
      email,
      password,
    });
  };

  const handleForgotPassword = async ({ email }: { email: string }) => {
    try {
      await forgotPasswordMutation.mutateAsync({
        email,
      });
    } catch (err: any) {
      if (err.code === 'UserNotFoundException') {
        return;
      }
      throw err;
    }
    setShowContent(ScreenContent.ConfirmForgotPassword);
  };

  const handleConfirmForgotPassword = async ({
    email,
    confirmCode,
    password,
  }: {
    email: string;
    confirmCode: string;
    password: string;
  }) => {
    if (confirmCode.length !== 6) {
      form.setError('confirmCode', {
        type: 'server',
        message: 'Invalid confirmation code',
      });
      return;
    }
    try {
      await confirmForgotPasswordMutation.mutateAsync({
        email,
        code: confirmCode,
        password,
      });
    } catch (err: any) {
      if (err.code === 'CodeMismatchException') {
        form.setError('confirmCode', {
          type: 'server',
          message: 'Invalid confirmation code',
        });
        return;
      } else if (err.code === 'ExpiredCodeException') {
        form.setError('confirmCode', {
          type: 'server',
          message: 'Expired confirmation code',
        });
        return;
      }
      throw err;
    }
    await handleLogin({
      email,
      password,
    });
  };

  const handleCompleteProfile = async (values: {
    password: string;
    firstName?: string;
    lastName?: string;
    nickname?: string;
  }) => {
    try {
      await completeNewPasswordChallengeMutation.mutateAsync(values);
    } catch (err: any) {
      throw err;
    }
    onLogin?.();
  };

  const handleSubmit = async (values: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    nickname: string;
    confirmCode: string[];
  }) => {
    const firstName = schema?.firstName ? values.firstName.trim() : undefined;
    const sanitizedValues = {
      ...values,
      email: values.email.trim(),
      firstName,
      lastName: schema?.lastName ? values.lastName.trim() : undefined,
      nickname: schema?.nickname ? values.nickname.trim() : firstName,
      confirmCode: values.confirmCode.join(''),
    };
    // Save email into storage
    await saveEmail(sanitizedValues.email).catch((err) => {
      console.error(`Failed to save email into storage: ${err}`);
    });
    switch (showContent) {
      case ScreenContent.Login:
        await handleLogin(sanitizedValues);
        break;
      case ScreenContent.Signup:
        await handleSignup(sanitizedValues);
        break;
      case ScreenContent.ConfirmCode:
        await handleCodeConfirm(sanitizedValues);
        break;
      case ScreenContent.ForgotPassword:
        await handleForgotPassword(sanitizedValues);
        break;
      case ScreenContent.ConfirmForgotPassword:
        await handleConfirmForgotPassword(sanitizedValues);
        break;
      case ScreenContent.CompleteProfile:
        await handleCompleteProfile(sanitizedValues);
        break;
      default:
        impossible(showContent);
    }
  };

  const fieldDisplayed = {
    confirmCode:
      showContent === ScreenContent.ConfirmCode ||
      showContent === ScreenContent.ConfirmForgotPassword,
    email:
      showContent === ScreenContent.Login ||
      showContent === ScreenContent.Signup ||
      showContent === ScreenContent.ForgotPassword ||
      showContent === ScreenContent.CompleteProfile,
    password:
      showContent === ScreenContent.Login ||
      showContent === ScreenContent.Signup ||
      showContent === ScreenContent.ConfirmForgotPassword ||
      showContent === ScreenContent.CompleteProfile,
    firstName:
      (showContent === ScreenContent.Signup || showContent === ScreenContent.CompleteProfile) &&
      schema?.firstName,
    lastName:
      (showContent === ScreenContent.Signup || showContent === ScreenContent.CompleteProfile) &&
      schema?.lastName,
    nickname:
      (showContent === ScreenContent.Signup || showContent === ScreenContent.CompleteProfile) &&
      schema?.nickname,
  };
  const getNextField = (currentField: keyof typeof fieldDisplayed) => {
    const fields = Object.keys(fieldDisplayed) as (keyof typeof fieldDisplayed)[];
    const currentFieldIndex = fields.indexOf(currentField);
    return fields.slice(currentFieldIndex + 1).find((field) => fieldDisplayed[field]);
  };
  const firstEditableField = (Object.keys(fieldDisplayed) as (keyof typeof fieldDisplayed)[]).find(
    (field) => {
      if (!fieldDisplayed[field]) return false;
      if (showContent === ScreenContent.CompleteProfile && field === 'email') return false;
      return true;
    },
  );
  useEffect(() => {
    if (!firstEditableField) return;
    form.setFocus(firstEditableField);
  }, [firstEditableField, form]);

  return (
    <FormProvider {...form}>
      <View style={containerStyle}>
        <Text style={styles.headerText}>
          {showContent === ScreenContent.Login
            ? 'Sign In'
            : showContent === ScreenContent.Signup
            ? 'Sign Up'
            : showContent === ScreenContent.ConfirmCode
            ? 'Verification Code'
            : showContent === ScreenContent.ForgotPassword
            ? 'Reset Password'
            : showContent === ScreenContent.ConfirmForgotPassword
            ? 'Set New Password'
            : showContent === ScreenContent.CompleteProfile
            ? 'Complete Profile'
            : impossible(showContent)}
        </Text>
        {showContent === ScreenContent.ConfirmCode ||
        showContent === ScreenContent.ConfirmForgotPassword ? (
          <Text style={styles.explainerText}>
            Enter verification code we have sent to your email address: {form.getValues().email}
          </Text>
        ) : showContent === ScreenContent.ForgotPassword ? (
          <Text style={styles.explainerText}>
            Enter your login email, and we'll send you a verification code.
          </Text>
        ) : showContent === ScreenContent.CompleteProfile ? (
          <Text style={styles.explainerText}>Please enter a new password and your name.</Text>
        ) : null}
        <View style={[fieldDisplayed.confirmCode ? undefined : styles.hidden]}>
          <CodeVerificationField
            name="confirmCode"
            onComplete={(code) => {
              const nextField = getNextField('confirmCode');
              if (nextField) {
                form.setFocus(nextField);
              } else {
                form.handleSubmit((values) => {
                  handleSubmit({
                    ...values,
                    confirmCode: code.split(''),
                  });
                })();
              }
            }}
            onResend={async () => {
              const { email } = form.getValues();
              if (!email) {
                form.setError('email', {
                  message: 'Email is required',
                });
                setShowContent(ScreenContent.Login);
                return;
              }
              await resendConfirmationCodeMutation.mutateAsync({ email });
            }}
          />
          <View style={styles.fieldSpacer} />
        </View>
        <View style={[fieldDisplayed.email ? undefined : styles.hidden]}>
          <EmailField<typeof defaultValues, 'email'>
            key="email"
            name="email"
            label="Email"
            autoFocus={firstEditableField === 'email'}
            editable={showContent !== ScreenContent.CompleteProfile}
            onSubmitEditing={() => {
              const nextField = getNextField('email');
              if (nextField) {
                form.setFocus(nextField);
              } else {
                form.handleSubmit(handleSubmit)();
              }
            }}
            returnKeyType={getNextField('email') ? 'next' : 'go'}
            placeholder="Your e-mail address"
            rules={{
              validate: (value) => {
                if (!fieldDisplayed.email) return true;
                if (!value) return 'Email is required';
                return true;
              },
            }}
          />
          <View style={styles.fieldSpacer} />
        </View>
        <View style={[fieldDisplayed.password ? undefined : styles.hidden]}>
          <TextField
            key="password"
            name="password"
            label="Password"
            icon="lock"
            autoFocus={firstEditableField === 'password'}
            autoComplete="password"
            onSubmitEditing={() => {
              const nextField = getNextField('password');
              if (nextField) {
                form.setFocus(nextField);
              } else {
                form.handleSubmit(handleSubmit)();
              }
            }}
            returnKeyType={getNextField('password') ? 'next' : 'go'}
            placeholder="Password"
            secureTextEntry={true}
            rules={{
              validate: (value) => {
                if (!fieldDisplayed.password) return true;
                if (!value) return 'Password is required';
                if (
                  showContent !== ScreenContent.Signup &&
                  showContent !== ScreenContent.ConfirmForgotPassword &&
                  showContent !== ScreenContent.CompleteProfile
                ) {
                  return true;
                }
                return isValidPassword(value);
              },
            }}
          />
          <View style={styles.fieldSpacer} />
        </View>
        <View style={[fieldDisplayed.firstName ? undefined : styles.hidden]}>
          <TextField
            key="firstName"
            name="firstName"
            label="First Name"
            icon="person"
            autoFocus={firstEditableField === 'firstName'}
            onSubmitEditing={() => {
              const nextField = getNextField('firstName');
              if (nextField) {
                form.setFocus(nextField);
              } else {
                form.handleSubmit(handleSubmit)();
              }
            }}
            returnKeyType={getNextField('firstName') ? 'next' : 'go'}
            placeholder="Your first name"
            rules={{
              validate: (value) => {
                if (!fieldDisplayed.firstName) return true;
                if (!value) return 'First Name is required';
                return true;
              },
            }}
          />
          <View style={styles.fieldSpacer} />
        </View>
        <View style={[fieldDisplayed.lastName ? undefined : styles.hidden]}>
          <TextField
            key="lastName"
            name="lastName"
            label="Last Name"
            icon="person"
            autoFocus={firstEditableField === 'lastName'}
            onSubmitEditing={() => {
              const nextField = getNextField('lastName');
              if (nextField) {
                form.setFocus(nextField);
              } else {
                form.handleSubmit(handleSubmit)();
              }
            }}
            returnKeyType={getNextField('lastName') ? 'next' : 'go'}
            placeholder="Your last name"
            rules={{
              validate: (value) => {
                if (!fieldDisplayed.lastName) return true;
                if (!value) return 'Last Name is required';
                return true;
              },
            }}
          />
          <View style={styles.fieldSpacer} />
        </View>
        <View style={[fieldDisplayed.nickname ? undefined : styles.hidden]}>
          <TextField
            key="nickname"
            name="nickname"
            label="Name"
            icon="person"
            autoFocus={firstEditableField === 'nickname'}
            onSubmitEditing={() => {
              const nextField = getNextField('nickname');
              if (nextField) {
                form.setFocus(nextField);
              } else {
                form.handleSubmit(handleSubmit)();
              }
            }}
            returnKeyType={getNextField('nickname') ? 'next' : 'go'}
            placeholder="Your nickname"
            rules={{
              validate: (value) => {
                if (!fieldDisplayed.nickname) return true;
                if (!value) return 'Name is required';
                return true;
              },
            }}
          />
          <View style={styles.fieldSpacer} />
        </View>
        {showContent === ScreenContent.Login ||
        showContent === ScreenContent.Signup ||
        showContent === ScreenContent.ForgotPassword ||
        showContent === ScreenContent.ConfirmForgotPassword ||
        showContent === ScreenContent.CompleteProfile ? (
          <Button
            loading={form.formState.isSubmitting}
            title={
              showContent === ScreenContent.Login
                ? 'Log In'
                : showContent === ScreenContent.Signup
                ? 'Sign Up'
                : showContent === ScreenContent.ForgotPassword
                ? 'Send Code'
                : showContent === ScreenContent.ConfirmForgotPassword
                ? 'Set New Password'
                : showContent === ScreenContent.CompleteProfile
                ? 'Complete'
                : impossible(showContent)
            }
            onPress={form.handleSubmit(handleSubmit)}
          />
        ) : null}
        {showContent === ScreenContent.Login ? (
          <View style={styles.forgotPasswordContainer}>
            <Pressable
              onPress={() => setShowContent(ScreenContent.ForgotPassword)}
              style={styles.forgotPasswordPressable}
            >
              <Text
                style={{
                  color: theme.neutral['04'],
                }}
              >
                Forgot your password?
              </Text>
            </Pressable>
          </View>
        ) : null}
        {showContent === ScreenContent.Login || showContent === ScreenContent.Signup ? (
          <View style={styles.bottomContainer}>
            <Pressable onPress={handleSwitch} style={styles.bottomPressable}>
              <Text
                style={{
                  color: theme.neutral['04'],
                }}
              >
                {showContent === ScreenContent.Login
                  ? 'Not a member yet? '
                  : showContent === ScreenContent.Signup
                  ? 'Already a member? '
                  : impossible(showContent)}
              </Text>
              <Text
                style={{
                  color: theme.neutral['08'],
                }}
              >
                {showContent === ScreenContent.Login
                  ? 'Sign up'
                  : showContent === ScreenContent.Signup
                  ? 'Sign in'
                  : impossible(showContent)}
              </Text>
            </Pressable>
          </View>
        ) : null}
      </View>
    </FormProvider>
  );
}

function isValidPassword(text: string): string | true {
  // https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
  if (text.length < 6) {
    return 'Must be at least 6 characters';
  }
  if (text.length > 99) {
    return 'Must be less than 99 characters';
  }
  if (
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-])[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]{8,98}$/.test(
      text,
    )
  ) {
    return 'Must have at least 1 uppercase, 1 lowercase, 1 number and 1 symbol';
  }
  return true;
}

const styles = StyleSheet.create({
  hidden: {
    display: 'none',
  },
  headerText: {
    fontSize: 32,
    lineHeight: 40,
    marginBottom: 24,
  },
  explainerText: {
    fontSize: 16,
    minHeight: 32,
    marginBottom: 18,
  },
  fieldSpacer: {
    flexShrink: 0,
    height: 4,
  },
  forgotPasswordContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 18,
    height: 36,
  },
  forgotPasswordPressable: {
    flexDirection: 'row',
  },
  bottomContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 18,
    height: 73,
  },
  bottomPressable: {
    flexDirection: 'row',
  },
});
