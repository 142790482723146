import { z } from 'zod';

import { buildCourseReleaseSchema } from '../course-release';

export const buildLearnProductSchema = () =>
  z.object({
    productId: z.string(),
    name: z.string(),
    description: z.string().optional(),
    entitlements: z
      .object({
        courses: z.record(buildCourseReleaseSchema().shape.courseId, z.boolean()),
      })
      .optional(),
  });
export type LearnProduct = z.infer<ReturnType<typeof buildLearnProductSchema>>;
