import { StyleSheet, View, ViewProps } from 'react-native';

import { Text } from '../Text';

export function Badge({
  containerStyle,
  content,
  size,
}: {
  containerStyle?: ViewProps['style'];
  content: string | number;
  size: number;
}) {
  return (
    <View
      style={[
        styles.container,
        {
          height: size,
          width: size,
          borderRadius: size / 2,
        },
        containerStyle,
      ]}
    >
      <Text
        style={[
          styles.text,
          {
            fontSize: Math.max(8, size - 5),
          },
        ]}
      >
        {content}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
  },
  text: {
    color: 'white',
  },
});
