import { useIsomorphicLayoutEffect } from '@innovigo/frontend-utils/hooks/useIsomorphicLayoutEffect';
import { useCallback, useRef } from 'react';

/**
 * https://github.com/reactjs/rfcs/blob/useevent/text/0000-useevent.md
 */
export function useEvent<T extends Function>(handler: T | undefined) {
  const handlerRef = useRef<typeof handler | null>(null);

  // In a real implementation, this would run before layout effects
  useIsomorphicLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback((...args: any[]) => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current;
    if (!fn) {
      return;
    }
    return fn(...args);
  }, []) as unknown as T;
}
