import { z } from 'zod';

export const buildCustomDomainSchema = () =>
  z.object({
    domain: z
      .string()
      .refine(
        (str) =>
          /^([a-z0-9](?:(?:[-a-z0-9]){0,61}[a-z0-9])?(?:\.[a-z0-9](?:(?:[-a-z0-9]){0,61}[a-z0-9])?){2,})$/.test(
            str,
          ),
        {
          message: 'Invalid domain name',
        },
      ),
    appId: z.string(),
    certificateArn: z.string(),
    certificateStatus: z.string(),
    dnsValidation: z
      .object({
        cnameName: z.string(),
        cnameValue: z.string(),
      })
      .optional(),
    callerReference: z.string(),
    distributionId: z.string().nullable().optional(),
    createdAt: z.string(),
    updatedAt: z.string(),
    lastCheckedAt: z.string().optional(),
  });
export type CustomDomain = z.infer<ReturnType<typeof buildCustomDomainSchema>>;
