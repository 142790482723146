import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { createAuthClient } from './create-auth-client';
import { AuthState, IAuthClient } from './types';

export const EMPTY_AUTH_STATE: AuthState = {
  userAttributes: null,
};

type AuthContextValue = Omit<IAuthClient, 'initializeAuthStateFromStorage'> &
  AuthState & {
    isLoadingAuthStateComplete: boolean;
  };

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export type AuthProviderProps = {
  children?: React.ReactNode;
  config?: Parameters<typeof createAuthClient>[0];
  initialState?: AuthState;
  onLoginSuccess?: (authState: AuthState) => any;
  onLogoutSuccess?: () => any;
};

export function AuthProvider({
  children,
  config,
  initialState,
  onLoginSuccess,
  onLogoutSuccess,
}: AuthProviderProps) {
  const authClient = useMemo(() => (config ? createAuthClient(config) : undefined), [config]);
  if (!authClient) return null;
  return (
    <AuthProviderInner
      authClient={authClient}
      initialState={initialState}
      onLoginSuccess={onLoginSuccess}
      onLogoutSuccess={onLogoutSuccess}
    >
      {children}
    </AuthProviderInner>
  );
}

function AuthProviderInner({
  authClient,
  children,
  initialState,
  onLoginSuccess,
  onLogoutSuccess,
}: {
  authClient: IAuthClient;
  children?: React.ReactNode;
  initialState?: AuthState;
  onLoginSuccess?: (authState: AuthState) => any;
  onLogoutSuccess?: () => any;
}) {
  const [isLoadingAuthStateComplete, setIsLoadingAuthStateComplete] = useState(false);
  const [state, setState] = useState(initialState || EMPTY_AUTH_STATE);

  useEffect(() => {
    async function firstLoad() {
      try {
        const initialState = await authClient.initializeAuthStateFromStorage();
        setState(initialState);
      } finally {
        setIsLoadingAuthStateComplete(true);
      }
    }
    firstLoad();
  }, [authClient]);

  const contextValue = useMemo(() => {
    return {
      ...state,
      isLoadingAuthStateComplete,
      async getAccessToken() {
        const accessToken = await authClient.getAccessToken();
        // TODO: open login popup if no access token
        return accessToken;
      },
      async getIdToken() {
        const idToken = await authClient.getIdToken();
        // TODO: open login popup if no id token
        return idToken;
      },
      signUp: authClient.signUp.bind(authClient),
      confirmSignUp: authClient.confirmSignUp.bind(authClient),
      async login(...args: Parameters<AuthContextValue['login']>) {
        const result = await authClient.login(...args);
        if (result.kind !== 'SUCCESS') return result;
        const { authState: newState } = result;
        try {
          await onLoginSuccess?.(newState);
        } catch (err) {
          console.error(err);
        }
        setState(newState);
        setIsLoadingAuthStateComplete(true);
        return result;
      },
      async logout(...args: Parameters<AuthContextValue['logout']>) {
        try {
          await authClient.logout(...args);
        } finally {
          try {
            await onLogoutSuccess?.();
          } catch (err) {
            console.error(err);
          }
          setState(EMPTY_AUTH_STATE);
        }
      },
      resendConfirmationCode: authClient.resendConfirmationCode.bind(authClient),
      forgotPassword: authClient.forgotPassword.bind(authClient),
      confirmForgotPassword: authClient.confirmForgotPassword.bind(authClient),
      async completeNewPasswordChallenge(
        ...args: Parameters<AuthContextValue['completeNewPasswordChallenge']>
      ) {
        const newState = await authClient.completeNewPasswordChallenge(...args);
        try {
          await onLoginSuccess?.(newState);
        } catch (err) {
          console.error(err);
        }
        setState(newState);
        setIsLoadingAuthStateComplete(true);
        return newState;
      },
    };
  }, [authClient, isLoadingAuthStateComplete, onLoginSuccess, onLogoutSuccess, state]);

  return (
    <AuthContext.Provider key={state.userAttributes?.id} value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const contextValue = useContext(AuthContext);
  if (!contextValue) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return contextValue;
}
