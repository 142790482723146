import { RootContainer } from '@innovigo/consumer-app-sdk/components/RootContainer';
import { AuthProvider } from '@innovigo/frontend-utils/auth';
import { ColorSchemePreferenceProvider } from '@innovigo/frontend-utils/color-scheme-preference';
import { ErrorBoundary } from '@innovigo/frontend-utils/error/ErrorBoundary';
import { NavBar } from '@innovigo/frontend-utils/navbar';
import { QueryClientProvider } from '@innovigo/frontend-utils/react-query';
import { IconProps } from '@innovigo/ui/components/Icon';
import { LoadingPage } from '@innovigo/ui/components/LoadingPage';
import { useEvent } from '@innovigo/ui/hooks/useEvent';
import { useResponsiveStyle } from '@innovigo/ui/hooks/useResponseStyle';
import { Suspense, lazy } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { StyleSheet, View } from 'react-native';
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { Logo } from './components/Logo';
import { NavLink } from './navbar/NavLink';
import { TopBar } from './navbar/TopBar';
import { CreatorApiProvider } from './providers/CreatorApiProvider';
import { CurrentOrgProvider } from './providers/CurrentOrgProvider';
import NotFoundScreen from './screens/NotFoundScreen';

const LoginScreen = lazy(
  () => import(/* webpackChunkName: "LoginScreen" */ './screens/LoginScreen'),
);
const CourseListScreen = lazy(
  () => import(/* webpackChunkName: "CourseListScreen" */ './screens/CourseListScreen'),
);
const CourseBuilderScreen = lazy(
  () => import(/* webpackChunkName: "CourseBuilderScreen" */ './screens/CourseBuilderScreen'),
);
const CourseDetailsScreen = lazy(
  () => import(/* webpackChunkName: "CourseDetailsScreen" */ './screens/CourseDetailsScreen'),
);
const ModuleDetailsScreen = lazy(
  () => import(/* webpackChunkName: "ModuleDetailsScreen" */ './screens/ModuleDetailsScreen'),
);
const LessonDetailsScreen = lazy(
  () => import(/* webpackChunkName: "LessonDetailsScreen" */ './screens/LessonDetailsScreen'),
);
const ViewEditorScreen = lazy(
  () => import(/* webpackChunkName: "ViewEditorScreen" */ './screens/ViewEditorScreen'),
);
const FileListScreen = lazy(
  () => import(/* webpackChunkName: "FileListScreen" */ './screens/FileListScreen'),
);
const AppListScreen = lazy(
  () => import(/* webpackChunkName: "AppListScreen" */ './screens/AppListScreen'),
);
const AppDetailsScreen = lazy(
  () => import(/* webpackChunkName: "AppDetailsScreen" */ './screens/AppDetailsScreen'),
);
const AppInfoScreen = lazy(
  () => import(/* webpackChunkName: "AppInfoScreen" */ './screens/AppInfoScreen'),
);
const AppCoursesScreen = lazy(
  () => import(/* webpackChunkName: "AppCoursesScreen" */ './screens/AppCoursesScreen'),
);
const AppProductsScreen = lazy(
  () => import(/* webpackChunkName: "AppProductsScreen" */ './screens/AppProductsScreen'),
);
const AppProductDetailsScreen = lazy(
  () =>
    import(/* webpackChunkName: "AppProductDetailsScreen" */ './screens/AppProductDetailsScreen'),
);
const AppAdminUsersScreen = lazy(
  () => import(/* webpackChunkName: "AppAdminUsersScreen" */ './screens/AppAdminUsersScreen'),
);
const AppCommunityScreen = lazy(
  () => import(/* webpackChunkName: "AppCommunityScreen" */ './screens/AppCommunityScreen'),
);
const AppUICustomizationScreen = lazy(
  () =>
    import(/* webpackChunkName: "AppUICustomizationScreen" */ './screens/AppUICustomizationScreen'),
);
const AppCustomDomainsScreen = lazy(
  () => import(/* webpackChunkName: "AppCustomDomainsScreen" */ './screens/AppCustomDomainsScreen'),
);
const SettingsScreen = lazy(
  () => import(/* webpackChunkName: "SettingsScreen" */ './screens/SettingsScreen'),
);

const NAV_LINK_CONFIG: {
  icon: IconProps['name'];
  label: string;
  to: string;
}[] = [
  {
    icon: 'school',
    label: 'Courses',
    to: '/builder/courses',
  },
  {
    icon: 'library-books',
    label: 'Files',
    to: '/builder/files',
  },
  {
    icon: 'apps',
    label: 'App',
    to: '/builder/apps',
  },
  {
    icon: 'settings',
    label: 'Settings',
    to: '/builder/settings',
  },
];

export function App() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingPage debugText="Root suspense" />}>
        <QueryClientProvider
          onError={(err) => toast.error(err instanceof Error ? err.message : JSON.stringify(err))}
          storageKey="innovigo/creator-web-app"
        >
          <ErrorBoundary>
            <ColorSchemePreferenceProvider>
              <Router>
                <Providers>
                  <ErrorBoundary>
                    <Suspense fallback={<LoadingPage debugText="Routes suspense" />}>
                      <Routes>
                        <Route path="login" element={<LoginScreen />} />
                        <Route path="/" element={<Layout />}>
                          <Route index element={<Navigate to="/builder/courses" replace />} />
                          <Route
                            path="builder"
                            element={<Navigate to="/builder/courses" replace />}
                          />
                          <Route
                            path="builder/courses"
                            element={
                              <CurrentOrgProvider>
                                <CourseListScreen />
                              </CurrentOrgProvider>
                            }
                          />
                          <Route
                            path="builder/courses/:courseId"
                            element={
                              <CurrentOrgProvider>
                                <CourseBuilderScreen />
                              </CurrentOrgProvider>
                            }
                          >
                            <Route index element={<CourseDetailsScreen />} />
                            <Route path="modules/:moduleId" element={<ModuleDetailsScreen />} />
                            <Route
                              path="lessons/:lessonId"
                              element={
                                <CurrentOrgProvider>
                                  <LessonDetailsScreen />
                                </CurrentOrgProvider>
                              }
                            />
                            <Route path="views/:viewId" element={<ViewEditorScreen />} />
                          </Route>
                          <Route
                            path="builder/files"
                            element={
                              <CurrentOrgProvider>
                                <FileListScreen />
                              </CurrentOrgProvider>
                            }
                          />
                          <Route
                            path="builder/apps"
                            element={
                              <CurrentOrgProvider>
                                <AppListScreen />
                              </CurrentOrgProvider>
                            }
                          />
                          <Route
                            path="builder/apps/:appId"
                            element={
                              <CurrentOrgProvider>
                                <AppDetailsScreen />
                              </CurrentOrgProvider>
                            }
                          >
                            <Route index element={<Navigate to="info" replace />} />
                            <Route path="info" element={<AppInfoScreen />} />
                            <Route path="courses" element={<AppCoursesScreen />} />
                            <Route path="products" element={<AppProductsScreen />}>
                              <Route path=":productId" element={<AppProductDetailsScreen />} />
                            </Route>
                            <Route path="admin-users" element={<AppAdminUsersScreen />} />
                            <Route path="community" element={<AppCommunityScreen />} />
                            <Route path="ui" element={<AppUICustomizationScreen />} />
                            <Route path="custom-domains" element={<AppCustomDomainsScreen />} />
                          </Route>
                          <Route path="builder/settings" element={<SettingsScreen />} />
                          <Route path="*" element={<NotFoundScreen />} />
                        </Route>
                      </Routes>
                    </Suspense>
                  </ErrorBoundary>
                </Providers>
              </Router>
            </ColorSchemePreferenceProvider>
          </ErrorBoundary>
        </QueryClientProvider>
        <Toaster />
      </Suspense>
    </ErrorBoundary>
  );
}

const authConfig = {
  userPoolId: process.env.REACT_APP_USER_POOL_ID as string,
  userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID as string,
};

function Providers({ children }: { children?: React.ReactNode }) {
  const navigate = useNavigate();

  const handleLogoutSuccess = useEvent(() => {
    navigate('/login');
  });

  return (
    <RootContainer
      style={useResponsiveStyle({
        mobile: {},
        tablet: {
          flexDirection: 'row-reverse',
        },
        desktop: {
          flexDirection: 'row-reverse',
        },
      })}
    >
      <AuthProvider config={authConfig} onLogoutSuccess={handleLogoutSuccess}>
        <CreatorApiProvider>{children}</CreatorApiProvider>
      </AuthProvider>
    </RootContainer>
  );
}

function Layout() {
  return (
    <>
      <View
        style={[
          styles.innerContainer,
          useResponsiveStyle({
            mobile: {},
            tablet: {
              paddingLeft: 2,
            },
            desktop: {
              paddingLeft: 2,
            },
          }),
        ]}
      >
        <TopBar />
        <View style={styles.outletContainer}>
          <ErrorBoundary>
            <Suspense fallback={<LoadingPage debugText="Outlet suspense" />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </View>
      </View>
      <NavBar
        logo={<Logo />}
        navLink={(props) => <NavLink {...props} />}
        navLinkConfig={NAV_LINK_CONFIG}
      />
    </>
  );
}

const styles = StyleSheet.create({
  innerContainer: {
    flex: 1,
  },
  outletContainer: {
    flex: 1,
  },
});
