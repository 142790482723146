import { z } from 'zod';

import { AppRelease, buildAppReleaseSchema } from './app-release';
import { buildCustomDomainSchema } from './custom-domain';

export const buildAppIntegrationsSchema = () =>
  z.object({
    scaleMonkey: z.object({
      webhookApiKey: z.string(),
    }),
  });

export const buildDraftAppSchema = () =>
  buildAppReleaseSchema()
    .omit({
      version: true,
    })
    .extend({
      orgId: z.string(),
      slug: buildAppReleaseSchema().shape.slug.optional(),
      /**
       * @deprecated
       */
      domain: buildAppReleaseSchema().shape.domain.optional(),
      courses: buildAppReleaseSchema().shape.courses.optional(),
      activeVersion: buildAppReleaseSchema().shape.version.optional(),
      integrations: buildAppIntegrationsSchema().optional(),
    });
export type DraftApp = z.infer<ReturnType<typeof buildDraftAppSchema>>;

export const buildCreateDraftAppInputSchema = () =>
  buildDraftAppSchema().pick({
    name: true,
    slug: true,
  });
export type CreateDraftAppInput = z.infer<ReturnType<typeof buildCreateDraftAppInputSchema>>;

export const buildUpdateDraftAppInputSchema = () =>
  buildDraftAppSchema()
    .pick({
      name: true,
      slug: true,
      logoImage: true,
      selectedDomain: true,
      fromEmailName: true,
      courses: true,
      products: true,
      chatSettings: true,
      ui: true,
    })
    .partial();
export type UpdateDraftAppInput = z.infer<ReturnType<typeof buildUpdateDraftAppInputSchema>>;

export const buildCreateAdminUserInputSchema = () =>
  z.object({
    email: z.string().email(),
  });
export type CreateAdminUserInput = z.infer<ReturnType<typeof buildCreateAdminUserInputSchema>>;

export const buildCreateCustomDomainInputSchema = () =>
  z.object({
    domain: buildCustomDomainSchema().shape.domain,
  });
export type CreateCustomDomainInput = z.infer<
  ReturnType<typeof buildCreateCustomDomainInputSchema>
>;

export type DraftAppWithActiveAppRelease = DraftApp & {
  activeAppRelease?: AppRelease;
  meta: {
    scaleMonkeyPurchaseApiUrl: string;
  };
};
