import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import {
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

const MAX_AGE = 1000 * 60 * 60 * 24 * 7; // 7 days

const suspenseCache = new Map<
  string,
  {
    queryClient: QueryClient;
    promise: Promise<any>;
    unsubscribe: () => void;
  }
>();

export function QueryClientProvider({
  children,
  onError,
  storageKey,
}: {
  children?: React.ReactNode;
  onError?: (err: unknown) => void;
  storageKey: string;
}) {
  const [{ queryClient, persister }] = useState(() => {
    const queryClient =
      suspenseCache.get(storageKey)?.queryClient ??
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: (err) => {
              onError?.(err);
            },
          },
          queries: {
            cacheTime: MAX_AGE,
            onError: (err) => {
              onError?.(err);
            },
            refetchOnWindowFocus: false,
            staleTime: 1000,
            suspense: true,
          },
        },
      });
    const persister = createAsyncStoragePersister({
      storage: AsyncStorage,
      key: storageKey,
      throttleTime: 1000,
    });
    return {
      queryClient,
      persister,
    };
  });

  if (!suspenseCache.has(storageKey)) {
    // Suspend until loaded
    const [unsubscribe, promise] = persistQueryClient({
      persister,
      queryClient,
      maxAge: MAX_AGE,
    });
    suspenseCache.set(storageKey, {
      queryClient,
      promise,
      unsubscribe,
    });
    throw promise;
  }
  useEffect(() => {
    return () => {
      suspenseCache.get(storageKey)?.unsubscribe();
      suspenseCache.delete(storageKey);
    };
  }, [storageKey]);

  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
      {Platform.OS === 'web' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </ReactQueryClientProvider>
  );
}
