import { isNonNullable } from '@innovigo/utils';
import * as Sentry from '@sentry/react';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoStorage,
  ISignUpResult,
} from 'amazon-cognito-identity-js';

import { AuthState, IAuthClient, UserAttributes } from './types';
import { getUserAttributesFromIdToken } from './utils';

export type CognitoAuthClientConfig = {
  storage?: ICognitoStorage;
  userPoolId: string;
  userPoolClientId: string;
};

export class CognitoAuthClient implements IAuthClient {
  private storage?: ICognitoStorage;
  private userPool: CognitoUserPool;
  private cognitoUser?: CognitoUser | null;

  constructor({ storage, userPoolId, userPoolClientId }: CognitoAuthClientConfig) {
    this.storage = storage;
    this.userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: userPoolClientId,
      Storage: this.storage,
    });
  }

  public getClientId() {
    return this.userPool.getClientId();
  }

  public async initializeAuthStateFromStorage() {
    const { cognitoUser, session } = await this.getSession();
    let userAttributes: UserAttributes | null = null;
    if (cognitoUser && session) {
      userAttributes = await new Promise<UserAttributes | null>((resolve) => {
        cognitoUser.getUserAttributes((err, result) => {
          if (result) {
            const id = result.find((att) => att.getName() === 'sub')?.getValue();
            if (!id) {
              resolve(null);
              return;
            }
            const attributes = {
              id,
              email: result.find((att) => att.getName() === 'email')?.getValue()!,
              firstName: result.find((att) => att.getName() === 'given_name')?.getValue(),
              lastName: result.find((att) => att.getName() === 'family_name')?.getValue(),
              nickname: result.find((att) => att.getName() === 'nickname')?.getValue(),
              userGroups: session.getIdToken().payload['cognito:groups'],
            };
            resolve(attributes);
          } else {
            resolve(null);
          }
        });
      });
      Sentry.setUser(userAttributes);
    }
    return {
      userAttributes,
    };
  }

  public async getAccessToken() {
    const { session } = await this.getSession();
    if (!session) return null;

    return session.getAccessToken().getJwtToken();
  }

  public async getIdToken() {
    const { session } = await this.getSession();
    if (!session) return null;

    return session.getIdToken().getJwtToken();
  }

  public async signUp({
    email,
    password,
    firstName,
    lastName,
    nickname,
  }: {
    email: string;
    password: string;
    firstName?: string;
    lastName?: string;
    nickname?: string;
  }): Promise<void> {
    const attributeList = [
      firstName
        ? new CognitoUserAttribute({
            Name: 'given_name',
            Value: firstName,
          })
        : undefined,
      lastName
        ? new CognitoUserAttribute({
            Name: 'family_name',
            Value: lastName,
          })
        : undefined,
      nickname
        ? new CognitoUserAttribute({
            Name: 'nickname',
            Value: nickname,
          })
        : undefined,
    ].filter(isNonNullable);

    await new Promise<ISignUpResult>((resolve, reject) => {
      this.userPool.signUp(
        email,
        password,
        attributeList,
        [],
        (err?: Error, data?: ISignUpResult) => {
          if (err) {
            reject(err);
          } else {
            resolve(data!);
          }
        },
      );
    });
  }

  public async confirmSignUp({ email, code }: { email: string; code: string }) {
    const user = new CognitoUser({
      Username: email,
      Pool: this.userPool,
      Storage: this.storage,
    });

    const confirmResult = await new Promise<any>((resolve, reject) => {
      user.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
    if (confirmResult !== 'SUCCESS') {
      throw new Error('Confirm sign up failed');
    }
  }

  public async login({ email, password }: { email: string; password: string }) {
    const user = new CognitoUser({
      Username: email,
      Pool: this.userPool,
      Storage: this.storage,
    });
    this.cognitoUser = user;

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const result = await new Promise<
      | {
          kind: 'SUCCESS';
          authState: AuthState;
        }
      | {
          kind: 'NEW_PASSWORD_REQUIRED';
          userAttributes: any;
          requiredAttributes: any;
        }
    >((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: (session) =>
          resolve({
            kind: 'SUCCESS',
            authState: {
              userAttributes: getUserAttributesFromIdToken(session.getIdToken()),
            },
          }),
        onFailure: reject,
        newPasswordRequired: (userAttributes, requiredAttributes) =>
          resolve({
            kind: 'NEW_PASSWORD_REQUIRED',
            userAttributes,
            requiredAttributes,
          }),
      });
    });
    if (result.kind === 'SUCCESS') {
      Sentry.setUser(result.authState.userAttributes);
    }

    return result;
  }

  public async logout(): Promise<void> {
    const cognitoUser = this.userPool.getCurrentUser();
    if (!cognitoUser) return;
    cognitoUser.signOut();
  }

  public async resendConfirmationCode({ email }: { email: string }): Promise<void> {
    const user = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    await new Promise((resolve, reject) => {
      user.resendConfirmationCode((error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve(data);
        }
      });
    });
  }

  public async forgotPassword({ email }: { email: string }): Promise<void> {
    const user = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    await new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: resolve,
        onFailure: reject,
      });
    });
  }

  public async confirmForgotPassword({
    email,
    code,
    password,
  }: {
    email: string;
    code: string;
    password: string;
  }): Promise<void> {
    const user = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    await new Promise((resolve, reject) => {
      user.confirmPassword(code, password, {
        onSuccess: resolve,
        onFailure: reject,
      });
    });
  }

  public async completeNewPasswordChallenge({
    password,
    firstName,
    lastName,
    nickname,
  }: {
    password: string;
    firstName?: string;
    lastName?: string;
    nickname?: string;
  }): Promise<AuthState> {
    const session = await new Promise<CognitoUserSession>((resolve, reject) => {
      if (!this.cognitoUser) {
        reject(new Error('Please login first'));
        return;
      }
      this.cognitoUser.completeNewPasswordChallenge(
        password,
        {
          given_name: firstName,
          family_name: lastName,
          nickname,
        },
        {
          onSuccess: resolve,
          onFailure: reject,
        },
      );
    });
    return {
      userAttributes: getUserAttributesFromIdToken(session.getIdToken()),
    };
  }

  private async getCognitoUser() {
    this.cognitoUser = (this.userPool as any).storage?.sync
      ? await new Promise<CognitoUser | null>((resolve, reject) => {
          (this.userPool as any).storage.sync((err: any, result: any) => {
            if (err) {
              reject(err);
            } else if (result === 'SUCCESS') {
              resolve(this.userPool.getCurrentUser());
            } else {
              resolve(null);
            }
          });
        })
      : this.userPool.getCurrentUser();
    // console.log('cognitoUser', this.cognitoUser);
    return this.cognitoUser;
  }

  private async getSession() {
    try {
      this.cognitoUser = this.cognitoUser ?? (await this.getCognitoUser());
      if (!this.cognitoUser) return {};

      const session = await new Promise<CognitoUserSession | null>((resolve, reject) => {
        this.cognitoUser!.getSession((err: any, session: CognitoUserSession | null) => {
          if (err) {
            reject(err);
          } else {
            resolve(session);
          }
        });
      });
      if (!session || !session.isValid()) return {};

      return {
        cognitoUser: this.cognitoUser,
        session,
      };
    } catch (err) {
      console.error(err);
      return {};
    }
  }
}
