import { z } from 'zod';

import { buildCourseReleaseSchema } from './course-release';
import { buildCustomDomainSchema } from './custom-domain';
import { buildDraftCourseSchema } from './draft-course';
import { buildDraftProductSchema } from './draft-product';

export const buildAppCourseConfigSchema = () =>
  z.object({
    version: z.union([buildCourseReleaseSchema().shape.version, z.literal('$LATEST')]),
    isFreeStartDate: z.string().optional(),
    isFreeEndDate: z.string().optional(),
    startDate: z.string().optional(),
    endDate: z.string().optional(),
  });
export type AppCourseConfig = z.infer<ReturnType<typeof buildAppCourseConfigSchema>>;

export const buildAppReleaseSchema = () =>
  z.object({
    appId: z.string(),
    version: z.number().int().positive(),
    slug: z
      .string()
      .min(4)
      .max(32)
      .regex(/^[a-z0-9][a-z0-9-]*/),
    logoImage: z.string().min(1).optional(),
    /**
     * @deprecated
     */
    domain: z.string().max(64).nullish(),
    /**
     * An app can have multiple domains but only one can be used in emails. The selected domain will
     * be the one used in emails. If not configured, the default bappo subdomain will be used.
     */
    selectedDomain: buildCustomDomainSchema().shape.domain.nullish(),
    name: z.string().min(1).max(64),
    fromEmailName: z.string().max(64).nullish(),
    fromEmailAddress: z.string().email().optional(),
    courses: z.record(buildCourseReleaseSchema().shape.courseId, buildAppCourseConfigSchema()),
    products: z.array(buildDraftProductSchema()).optional(),
    adminUserEmails: z.array(z.string().email()).optional(),
    chatSettings: z
      .object({
        enabled: z.boolean().optional(),
        enableNewsChannel: z.boolean().optional(),
        newsChannelName: z.string().max(64).nullish(),
        enableSupportChannels: z.boolean().optional(),
      })
      .optional(),
    ui: z
      .object({
        navbar: z
          .object({
            labels: z
              .object({
                learn: z.string().nullish(),
                chat: z.string().nullish(),
                settings: z.string().nullish(),
                cohorts: z.string().nullish(),
                students: z.string().nullish(),
                monitor: z.string().nullish(),
              })
              .optional(),
          })
          .optional(),
      })
      .optional(),
    createdAt: z.string(),
    updatedAt: z.string(),
  });
export type AppRelease = z.infer<ReturnType<typeof buildAppReleaseSchema>>;

export const buildCreateAppReleaseInputSchema = () =>
  buildAppReleaseSchema().omit({
    appId: true,
    version: true,
    createdAt: true,
    updatedAt: true,
  });
export type CreateAppReleaseInput = z.infer<ReturnType<typeof buildCreateAppReleaseInputSchema>>;

export const buildPublishAppInputSchema = () =>
  z.object({
    draftAppUpdatedAt: buildAppReleaseSchema().shape.updatedAt,
    draftCoursesUpdatedAt: z.record(
      buildDraftCourseSchema().shape.courseId,
      buildDraftCourseSchema().shape.updatedAt,
    ),
  });
export type PublishAppInput = z.infer<ReturnType<typeof buildPublishAppInputSchema>>;
