import { SafeAreaView, StyleSheet, View } from 'react-native';

import { IconButton } from './IconButton';
import { Text } from './Text';

export function ErrorPage({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) {
  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.innerContainer}>
        <Text>{children}</Text>
        {onClose ? (
          <IconButton name="close" onPress={onClose} size={24} style={styles.closeButton} />
        ) : null}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
});
