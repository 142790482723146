import { CookieStorage } from 'amazon-cognito-identity-js';
import Cookies from 'js-cookie';

import { CognitoAuthClient } from './CognitoAuthClient';
import { IAuthClient } from './types';

export function createAuthClient(config: {
  domain?: string;
  userPoolId: string;
  userPoolClientId: string;
}): IAuthClient {
  const domain = typeof window !== 'undefined' ? window.location.hostname : config.domain;
  const authClient = new CognitoAuthClient({
    storage: domain
      ? new CookieStorage({
          domain,
          secure: domain !== 'localhost' && !/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.exec(domain),
        })
      : undefined,
    userPoolId: config.userPoolId,
    userPoolClientId: config.userPoolClientId,
  });

  // Clear cookies for other users, otherwise we might get "Request Header Fields Too Large" error
  if (typeof window !== 'undefined') {
    try {
      const clientId = authClient.getClientId();
      const cookies = Cookies.get();
      const lastAuthUser = cookies[`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`];
      Object.keys(cookies).forEach((name) => {
        if (!name.startsWith('CognitoIdentityServiceProvider.')) return;
        if (!name.startsWith(`CognitoIdentityServiceProvider.${clientId}.`)) {
          // Other user pools
          Cookies.remove(name);
        }
        if (
          lastAuthUser &&
          !name.startsWith(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.`) &&
          (name.endsWith('idToken') ||
            name.endsWith('accessToken') ||
            name.endsWith('refreshToken'))
        ) {
          // Only remove tokens for other users and keep device related cookies
          Cookies.remove(name);
        }
      });
    } catch (err) {
      // Catch error to avoid blocking the rest of the function
      console.error(`Failed to clear obsolete Cogntio cookies`);
    }
  }
  return authClient;
}
