import { z } from 'zod';

import { buildSubscriptionSchema } from '../consumer-sub';
import { buildCreateStudentInputSchema } from '../student';

export enum SubscriptionEventType {
  SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED',
}

export const buildSubscriptionCreatedEventSchema = () =>
  z.object({
    ...buildCreateStudentInputSchema().shape,
    appId: z.string(),
    subscription: buildSubscriptionSchema(),
  });
