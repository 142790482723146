import {
  CreateAdminUserInput,
  CreateCustomDomainInput,
  CreateDraftAppInput,
  CreateDraftCourseInput,
  CreateDraftFileInput,
  CreateDraftViewInput,
  CustomDomain,
  DraftApp,
  DraftAppWithActiveAppRelease,
  DraftCourse,
  DraftCourseWithCoverImage,
  DraftFile,
  DraftFileWithThumbnails,
  DraftView,
  PublishAppInput,
  UpdateDraftAppInput,
  UpdateDraftCourseInput,
  UpdateDraftFileInput,
  UpdateDraftViewInput,
  buildCreateAdminUserInputSchema,
  buildCreateCustomDomainInputSchema,
  buildUpdateDraftAppInputSchema,
} from '@innovigo/types';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { errorLogger, requestLogger } from 'axios-logger';
import { errorLoggerWithoutPromise } from 'axios-logger/lib/logger/error';
import { pick } from 'lodash';
import { set } from 'lodash';

import { ApiError, buildApiError } from './error';
import { ApiResponse } from './types';

export interface CreatorApiPrivateClientConfig {
  getAccessToken: () => Promise<string | null>;
  endpoint?: string;
}

export class CreatorApiPrivateClient {
  private axios: AxiosInstance;

  constructor(private readonly config: CreatorApiPrivateClientConfig) {
    this.axios = axios.create({
      baseURL: config.endpoint ?? '/api',
    });
    this.axios.interceptors.request.use(async (requestConfig) => {
      // Set access token as Authorization header
      const accessToken = await config.getAccessToken();
      if (!accessToken) {
        // Could not get a valid access token, abort request
        const control = new AbortController();
        control.abort();
        return {
          ...requestConfig,
          signal: control.signal,
        };
      }
      set(requestConfig, 'headers.Authorization', accessToken);

      return requestLogger(requestConfig) as any;
    }, errorLogger);
    this.axios.interceptors.response.use(undefined, async (err) => {
      errorLoggerWithoutPromise(err);

      throw buildApiError(err);
    });
  }

  private async getItem<T>(url: string, requestConfig?: AxiosRequestConfig) {
    try {
      const { data } = await this.axios.get<ApiResponse<T>>(url, requestConfig);
      return data.data ?? null;
    } catch (err) {
      if (err instanceof ApiError && err.code === 'NotFound') {
        return null;
      }
      throw err;
    }
  }

  async createOrgCourse(
    orgId: string,
    input: CreateDraftCourseInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/orgs/${orgId}/courses`;
    const { data } = await this.axios.post<
      typeof input,
      {
        data: ApiResponse<DraftCourse>;
      }
    >(url, input, requestConfig);
    return data.data;
  }

  async getOrgCourses(orgId: string, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/orgs/${orgId}/courses`;
    const { data } = await this.axios.get<ApiResponse<DraftCourseWithCoverImage[]>>(
      url,
      requestConfig,
    );
    return data.data;
  }

  async getCourse(courseId: string, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/courses/${courseId}`;
    return this.getItem<DraftCourse>(url, requestConfig);
  }

  async updateCourse(
    courseId: string,
    input: UpdateDraftCourseInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/courses/${courseId}`;
    await this.axios.patch(url, input, requestConfig);
  }

  async createOrgView(
    orgId: string,
    input: CreateDraftViewInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/orgs/${orgId}/views`;
    const { data } = await this.axios.post<
      typeof input,
      {
        data: ApiResponse<DraftView>;
      }
    >(url, input, requestConfig);
    return data.data;
  }

  async getView(viewId: string, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/views/${viewId}`;
    return this.getItem<DraftView>(url, requestConfig);
  }

  async updateView(
    viewId: string,
    input: UpdateDraftViewInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/views/${viewId}`;
    await this.axios.patch(url, input, requestConfig);
  }

  async createOrgFile<TFile extends DraftFile>(
    orgId: string,
    input: CreateDraftFileInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/orgs/${orgId}/files`;
    const { data } = await this.axios.post<
      typeof input,
      {
        data: ApiResponse<
          TFile,
          {
            uploadUrl: string;
            tags: string;
          }
        >;
      }
    >(url, input, requestConfig);
    return data;
  }

  async getOrgFiles(orgId: string, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/orgs/${orgId}/files`;
    const { data } = await this.axios.get<ApiResponse<DraftFileWithThumbnails[]>>(
      url,
      requestConfig,
    );
    return data.data;
  }

  async getFile<
    TData extends DraftFile,
    TMeta extends {
      downloadUrl: string;
    },
  >(fileId: string, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/files/${fileId}`;
    try {
      const { data } = await this.axios.get<ApiResponse<TData, TMeta>>(url, requestConfig);
      return data;
    } catch (err) {
      if (err instanceof ApiError && err.code === 'NotFound') {
        return null;
      }
      throw err;
    }
  }

  async updateFile(
    fileId: string,
    input: UpdateDraftFileInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/files/${fileId}`;
    await this.axios.patch(url, input, requestConfig);
  }

  async createOrgApp(
    orgId: string,
    input: CreateDraftAppInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/orgs/${orgId}/apps`;
    const { data } = await this.axios.post<
      typeof input,
      {
        data: ApiResponse<DraftApp>;
      }
    >(url, input, requestConfig);
    return data.data;
  }

  async getOrgApps(orgId: string, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/orgs/${orgId}/apps`;
    const { data } = await this.axios.get<
      typeof requestConfig,
      {
        data: ApiResponse<DraftApp[]>;
      }
    >(url, requestConfig);
    return data.data;
  }

  async getApp(appId: string, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/apps/${appId}`;
    return this.getItem<DraftAppWithActiveAppRelease>(url, requestConfig);
  }

  async updateApp(appId: string, input: UpdateDraftAppInput, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/apps/${appId}`;
    const { data } = await this.axios.patch(
      url,
      pick(input, buildUpdateDraftAppInputSchema().keyof().options),
      requestConfig,
    );
    return data.data;
  }

  async createAdminUser(
    appId: string,
    input: CreateAdminUserInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/apps/${appId}/adminUsers`;
    await this.axios.post(
      url,
      pick(input, buildCreateAdminUserInputSchema().keyof().options),
      requestConfig,
    );
  }

  async createCustomDomain(
    appId: string,
    input: CreateCustomDomainInput,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/apps/${appId}/customDomains`;
    await this.axios.post(
      url,
      pick(input, buildCreateCustomDomainInputSchema().keyof().options),
      requestConfig,
    );
  }

  async listCustomDomains(appId: string, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/apps/${appId}/customDomains`;
    const { data } = await this.axios.get<ApiResponse<CustomDomain[]>>(url, requestConfig);
    return data.data;
  }

  async validateCustomDomain(
    appId: string,
    customDomain: string,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/apps/${appId}/customDomains/${customDomain}/validate`;
    await this.axios.post(url, {}, requestConfig);
  }

  async deleteCustomDomain(
    appId: string,
    customDomain: string,
    requestConfig?: AxiosRequestConfig,
  ) {
    const url = `/builder/apps/${appId}/customDomains/${customDomain}`;
    await this.axios.delete(url, requestConfig);
  }

  async publishApp(appId: string, input: PublishAppInput, requestConfig?: AxiosRequestConfig) {
    const url = `/builder/apps/${appId}/publish`;
    await this.axios.post(url, input, requestConfig);
  }
}
