import { z } from 'zod';

import { buildAuthenticatedUserSchema } from '../user';

export const buildChatUserSchema = () =>
  buildAuthenticatedUserSchema().extend({
    chatUserId: z.string(),
    streamUserId: z.string(),
  });
export type ChatUser = z.infer<ReturnType<typeof buildChatUserSchema>>;

export const buildChatPushTokenSchema = () =>
  z.object({
    chatUserId: z.string(),
    pushToken: z.string(),
    deviceName: z.string().nullish(),
    deviceModel: z.string().nullish(),
    deviceOS: z.string().nullish(),
    deviceOSVersion: z.string().nullish(),
  });
export type ChatPushToken = z.infer<ReturnType<typeof buildChatPushTokenSchema>>;
