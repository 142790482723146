import type { FieldPath, FieldPathValue, FieldValues } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';

import { TextField, TextFieldProps } from '../TextField';

export type EmailFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> = Omit<TextFieldProps<TFieldValues, TName>, 'autoComplete' | 'multiline'>;

export function EmailField<TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>(
  props: EmailFieldProps<TFieldValues, TName>,
) {
  return (
    <TextField
      {...props}
      autoComplete="email"
      icon="email"
      rules={{
        ...props.rules,
        validate: {
          ...(typeof props.rules?.validate === 'function'
            ? {
                custom: props.rules.validate,
              }
            : props.rules?.validate),
          _isEmail: (text) => (isEmail(text) ? true : 'Invalid email addess'),
        },
      }}
      transform={{
        parse: (text) => text.trim().toLowerCase() as FieldPathValue<TFieldValues, TName>,
      }}
    />
  );
}
