import { Pressable, PressableProps, StyleSheet } from 'react-native';

import { Icon, IconProps } from '../Icon';

export type IconButtonProps = Pick<IconProps, 'color' | 'name' | 'size'> &
  Omit<PressableProps, 'children'>;

export function IconButton({ color, name, size, ...pressableProps }: IconButtonProps) {
  const iconProps = {
    color,
    name,
    size,
  };
  return (
    <Pressable
      {...pressableProps}
      style={(state) => [
        styles.pressable,
        {
          height: size,
          width: size,
        },
        typeof pressableProps.style === 'function'
          ? pressableProps.style(state)
          : pressableProps.style,
      ]}
    >
      <Icon {...iconProps} />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  pressable: {
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
