import { z } from 'zod';

export const PAYMENT_GATEWAYS = ['app-store', 'play-store', 'stripe', 'scale-monkey'] as const;
export const buildPaymentGatewaySchema = () => z.enum(PAYMENT_GATEWAYS);
export type PaymentGateway = z.infer<ReturnType<typeof buildPaymentGatewaySchema>>;

export const buildBasePriceSchema = () =>
  z.object({
    priceId: z.string(),
    paymentGateway: buildPaymentGatewaySchema(),
    billingType: z.enum(['one-time', 'recurring']),
    currency: z.string(),
    amountInCents: z.number().int().min(0),
    paymentConfig: z.any(),
    startDate: z.string().datetime().nullable().optional(),
    endDate: z.string().datetime().nullable().optional(),
  });
const buildPriceSchema = () =>
  z.discriminatedUnion('billingType', [
    buildBasePriceSchema().extend({
      billingType: z.literal('one-time'),
    }),
    buildBasePriceSchema().extend({
      billingType: z.literal('recurring'),
      billingPeriod: z.enum(['monthly']),
    }),
  ]);

export const buildSubscriptionProductSchema = () =>
  z.object({
    productId: z.string(),
    name: z.string().min(1).max(64),
    description: z.string().max(512).optional(),
    prices: z.array(buildPriceSchema()),
    // Time when it will become available for purchase.
    startDate: z.string().datetime().nullable().optional(),
    // No end date means it's always available for purchase after start date.
    // Even if it has passed the end date, users who have purchased it will keep their entitlements.
    endDate: z.string().datetime().nullable().optional(),
    scaleMonkeyProductId: z.string().min(1).max(64).optional(),
  });
export type SubscriptionProduct = z.infer<ReturnType<typeof buildSubscriptionProductSchema>>;
